
import { defineComponent, inject, ref, reactive, toRef, computed } from 'vue'
import { employee, modalInstance, notifyInstance } from '@/type'
import Lightbox from '@/views/components/lightbox.vue'
import moment from 'moment'
import usePagination from '@/plugins/usePagination'
import { useStore } from 'vuex'
import axios from '@/http'
import fileRequest from '@/http/file'

moment.locale('zh-tw')
export default defineComponent({
  components: {
    Lightbox
  },
  setup() {
    const modal = inject('modal') as modalInstance
    const notify = inject('notify') as notifyInstance
    const now = ref('')

    const store = useStore()
    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const globalVariable = computed(() => store.getters.globalVariable)
    const paymentsMap = computed(() => globalVariable.value.payments)
    const employees = ref(new Array<employee>())

    const previewModal = reactive({
      modalSize: 'lg',
      contentAlign: 'left'
    })
    const previewRef = ref()
    const previewJobRecord = reactive({})

    const statusMap = {
      1: {
        class: '',
        text: '排隊中'
      },
      2: {
        class: '',
        text: '處理中'
      },
      3: {
        class: 'is-success',
        text: '已完成'
      },
      4: {
        class: '',
        text: '已取消'
      }
    }

    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 10,
      lastPage: 1,
      total: 0,
      jobRecords: ref(new Array<any>())
    })

    const paginationSetting = reactive({
      page: toRef(paginationData, 'page'),
      pageSize: toRef(paginationData, 'pageSize'),
      api: `/jobsRecords/${storeId.value}`
    })

    function preview(jobRecord: any) {
      Object.assign(previewJobRecord, jobRecord)
      previewRef.value.open()
    }

    function download(jobRecord: any) {
      if (
        jobRecord.status === 3 &&
        moment().isBefore(moment(jobRecord.deadAt).format('YYYY-MM-DD HH:mm'))
      ) {
        fileRequest
          .get(
            `/download/${storeId.value}/${jobRecord.type}/${jobRecord.zipFilePrefix}`,
            { responseType: 'blob' }
          )
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]))
            var fURL = document.createElement('a')
            fURL.href = fileURL
            fURL.setAttribute('download', `${jobRecord.zipFilePrefix}.zip`)
            document.body.appendChild(fURL)
            fURL.click()
            return true
          })
          .catch((err) => {
            const errorMsg = '下載已失效'
            const blob = err.response.data
            blob.text().then((res: any) =>
              notify(
                {
                  classType: 'error',
                  message: JSON.parse(res).errors.detail ?? errorMsg,
                  closeAble: true
                },
                5000
              )
            )

            return false
          })
      } else {
        notify(
          {
            classType: 'error',
            message: '下載已失效',
            closeAble: true
          },
          5000
        )
        return false
      }
    }

    function padLeft(val: string | number, len: number) {
      const str = '' + val
      return str.length >= len
        ? str
        : new Array(len - str.length + 1).join('0') + str
    }

    function getNow() {
      const date = new Date()
      const year = padLeft(date.getFullYear(), 2)
      const month = padLeft(date.getMonth() + 1, 2)
      const day = padLeft(date.getDate(), 2)
      const hours = padLeft(date.getHours(), 2)
      const minutes = padLeft(date.getMinutes(), 2)
      const seconds = padLeft(date.getSeconds(), 2)
      return `${year}年${month}月${day}日 ${hours}：${minutes}：${seconds}`
    }

    function reload() {
      init()
    }

    function cancel(jobRecord: any) {
      const cancelScheduleModal = modal({
        content: `確定「 取消 ${jobRecord.name} 」排程?`,
        classType: 'alert',
        confirmBtnClick: () => {
          return new Promise((resolve, reject) => {
            axios
              .post(`/jobsRecords/${storeId.value}/${jobRecord.id}`)
              .then((res) => {
                goPage(pagination?.getData().currentPage)
                resolve(res.data)
              })
              .catch((err) => {
                notify(
                  {
                    classType: 'error',
                    message: err.response.errors.detail,
                    closeAble: true
                  },
                  60000
                )
                reject(err)
              })
          })
        }
      })
      cancelScheduleModal.show()
    }

    function init() {
      now.value = getNow()
      const initEmployee = axios
        .get(`/employees/${storeId.value}`)
        .then((res) => {
          const data = res.data
          employees.value = data.employees
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
      const initPagination = usePagination(paginationSetting).then((res) => {
        pagination = res
      })

      Promise.all([initEmployee, initPagination]).then(function () {
        getData()
      })
    }

    init()

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.jobRecords = data.items
    }

    function getEmployeeName(employeeNo: string) {
      const employee = employees.value.find((employee: employee) => {
        return employee.no === employeeNo
      })
      if (employee !== undefined) {
        return employee.name
      }
      return ''
    }

    return {
      cancel,
      reload,
      goPage,
      prePage,
      nextPage,
      paginationData,
      preview,
      now,
      statusMap,
      previewRef,
      previewJobRecord,
      previewModal,
      paymentsMap,
      download,
      getEmployeeName
    }
  }
})
